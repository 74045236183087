import { render, staticRenderFns } from "./CourseSlider.vue?vue&type=template&id=76e087ae&scoped=true"
import script from "./CourseSlider.vue?vue&type=script&lang=js"
export * from "./CourseSlider.vue?vue&type=script&lang=js"
import style0 from "./CourseSlider.vue?vue&type=style&index=0&id=76e087ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76e087ae",
  null
  
)

export default component.exports