<template>
  <section
    :class="[
      'common-section course__slider--section',
      { 'course-page': isOnCourse },
      { 'd-none': courseList.length === 0 }
    ]"
  >
    <div class="container" v-if="courseList.length > 0">
      <div class="row">
        <div class="col-12">
          <div class="intro-container">
            <i18next
              path="course.banner_section.title"
              tag="div"
              class="heading"
              v-if="isOnCourse"
            >
              <span>
                {{ $t("course.banner_section.title_0") }}
              </span>
            </i18next>
            <i18next
              path="landing_page.course_section.title"
              tag="div"
              class="heading"
              v-else
            >
              <span>
                {{ $t("landing_page.course_section.title_0") }}
              </span>
            </i18next>
            <div class="content" v-if="!isOnCourse">
              {{ $t("landing_page.course_section.content") }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 course-container"
          :style="{
            '--slides-length': courseList.length,
            '--animation-length': Math.floor(courseList.length / 2)
          }"
        >
          <div
            :class="[
              'course-slider',
              {
                'autoplay-animation': setAnimation,
                'pause-animation': pauseAnimation
              }
            ]"
          >
            <CourseCard
              :course="course"
              @cardClick="onCardClick"
              @cardHover="onCardHover"
              v-for="course in courseList"
              :key="course.id"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="showBtn">
        <div class="col-12 btn-container">
          <LxpButton variant="accent" @click="handleOnClick">
            {{ $t("landing_page.course_section.button") }}
          </LxpButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CourseCard from "./CourseCard.vue";
import { LxpButton } from "didactica";
export default {
  props: {
    isOnCourse: {
      type: Boolean,
      default: false
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  components: { CourseCard, LxpButton },
  data() {
    return {
      courseList: [],
      animationActive: false,
      isMobilePortrait: false,
      isMobileLandscape: false,
      isTabPortrait: false,
      isTabLandscape: false,
      isDesktop: false,
      pauseAnimation: false
    };
  },
  computed: {
    setAnimation() {
      return (
        (this.isDesktop && this.courseList.length > 5) ||
        (this.isTabLandscape && this.courseList.length > 4) ||
        (this.isTabPortrait && this.courseList.length > 3) ||
        (this.isMobileLandscape && this.courseList.length > 2) ||
        (this.isMobilePortrait && this.courseList.length > 1)
      );
    }
  },
  mounted() {
    this.getCourseList();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobilePortrait = window.innerWidth < 480;
      this.isMobileLandscape =
        window.innerWidth > 479 && window.innerWidth < 768;
      this.isTabPortrait = window.innerWidth > 767 && window.innerWidth < 992;
      this.isTabLandscape = window.innerWidth > 991 && window.innerWidth < 1200;
      this.isDesktop = window.innerWidth > 1199;
    },
    getCourseList() {
      let params = {
        pageNumber: 1,
        pageSize: 20,
        searchText: "",
        language: this.logoLanguage
      };
      this.$store
        .dispatch("getSearchList", params)
        .then(response => {
          this.courseList = response.results;
        })
        .catch(() => {
          this.courseList = [];
        });
    },
    onCardClick(course) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: course.id },
        query: { source: course.source }
      });
    },
    onCardHover(value) {
      this.pauseAnimation = value;
    },
    handleOnClick() {
      this.$router.push({
        name: "Courses"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.course__slider--section {
  background-color: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  font-family: $font-family;
  &.course-page {
    background-color: $brand-primary-100;
  }
  .intro-container {
    .heading {
      @include h3;
      color: $brand-neutral-900;
      span {
        @include gradient-text;
        font-weight: 600;
      }
    }
    .content {
      margin-top: 24px;
      @include subtitle-regular;
      font-weight: 400;
      color: $brand-neutral-700;
      max-width: calc(100% - 390px);
      margin-left: auto;
      margin-right: auto;
    }
  }
  .course-container {
    margin-top: 48px;
  }
  .course-slider {
    display: flex;
    width: 100%;
    &.autoplay-animation {
      width: calc(230px * var(--slides-length));
      animation: scroll 40s linear infinite;
      &.pause-animation {
        animation-play-state: paused;
      }
    }
    &:not(.autoplay-animation) {
      justify-content: center;
    }
    > div:not(:first-child) {
      margin-left: 16px;
    }
  }
  .btn-container {
    margin-top: 32px;
    @include flex-horizontal-center;
    .btn {
      height: 54px;
      min-width: 211px;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-230px * var(--animation-length)));
  }
}

@media only screen and (max-width: 1199px) {
  .course__slider--section {
    .intro-container {
      .content {
        max-width: calc(100% - 280px);
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .course__slider--section {
    .intro-container {
      .heading {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.08px;
        margin-left: auto;
        margin-right: auto;
      }
      .heading,
      .content {
        max-width: calc(100% - 106px);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .course__slider--section {
    .intro-container {
      .heading,
      .content {
        max-width: calc(100% - 32px);
      }
    }
  }
}

[dir="rtl"] {
  .course__slider--section {
    .course-slider {
      > div {
        &:not(:first-child) {
          margin-left: 0px;
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
